import { SceneObject, SceneObjects } from "./scene_objects.js";
import { Interactives } from "./interactives.js";
import { AudioAmbiance } from "../audio/audioAmbiance.js";
import { Interactive } from "./Interactive.js";
import { ScenePath } from "./ScenePath.js";
import { InteractiveLayer, InteractiveLayerJson } from "./InteractiveLayer.js";
import { InteractiveLayers } from "./InteractiveLayers.js";
import { AudioListenerScope } from "../audio/AudioListenerScope.js";
import { AmbianceContext } from "../audio/AmbianceContext.js";
let c2 = require("c2.js");

export class Scene {
  scene_graph;
  scene_graph_node;
  name;
  json;
  visual_resource;
  audioAmbiance;
  canvasElement;

  objects;
  interactiveLayers;

  get sceneInteractiveLayer() {
    return this.interactiveLayers.default_layer;
  }

  get resources() {
    return this.scene_graph.resources;
  }
  get icanvas() {
    return this.scene_graph_node.icanvas;
  }
  get simulation() {
    return this.scene_graph_node.simulation;
  }
  get sceneGraph() {
    return this.scene_graph;
  }
  get sceneLayerOrder() {
    return this.json.sceneLayerOrder;
  }  
  activate_event(ievent) {
    this.interactiveLayers.activate_event(ievent);
  }
  activate(value, value_context) {
    this.interactiveLayers.activate(value, value_context);
  }
  constructor(scene_graph, name, json) {
    this.scene_graph = scene_graph;
    this.name = name;
    this.json = json;
    if (this.json.sceneLayerOrder == undefined) {
      this.json.sceneLayerOrder = 1;
    }
    this.objects = new SceneObjects(this);
    this.interactiveLayers = new InteractiveLayers(this);
    this.interactiveLayers.pushDefaultLayer();

    if (this.json.inactivityFadeoutSeconds != undefined) {
      this.interactiveLayers.default_layer.json.inactivityFadeoutSeconds = this.json.inactivityFadeoutSeconds;
    }

    for (let each in this.json.interactive) {
      var i = new Interactive(this.json.interactive[each]);
      i.scene = this;
      this.interactiveLayers.addInteractive(i);
    }

    //if (this.json.audioAmbiance && !this.audioAmbiance) {
      this.audioAmbiance = new AudioAmbiance(
        this.toAudioContext(),
        this.json.audioAmbiance,
        this.getResourcePath.bind(this)
      );
  // }
  }
  getResourcePathFunction(){
    return this.getResourcePath.bind(this);
  }

  toAudioContext() {
    var audioContext = new AmbianceContext(AudioListenerScope.fromScenePath(this.toScenePath(), this, this.sceneGraph));
    return audioContext;
  }

  toScenePath() {
    return new ScenePath(this.scene_graph.name, this.name);
  }

  push(item) {
    if (item instanceof Interactive) {
      this.interactiveLayers.addInteractive(item);
      item.scene = this;
    } else if (item instanceof SceneObject) {
      this.objects.sceneObjects.push(item);
    } else if (item instanceof InteractiveLayerJson) {
      this.interactiveLayers.pushLayer(item.json);
    }
  }

  removeTemporary() {
    this.sceneInteractiveLayer.removeTemporary();
  }
  canNavigateBack() {
    return this.simulation.player.canNavigateBack();
  }
  navigateBack() {
    this.simulation.player.navigateBack();
  }
  navigateHome() {
    this.simulation.player.navigateHome();
  }
  navigate(scene_name, scene_graph_name = undefined) {
    if (scene_graph_name == undefined) {
      scene_graph_name = this.sceneGraph.name;
    }
    this.simulation.player.updateLocation(new ScenePath(scene_graph_name, scene_name));
  }

  graphStarted(previousPath = undefined) {}

  start(previousScene = undefined) {
    this.set_visual();

    this.scene_graph_node.listener.addAudioAmbiance(this.audioAmbiance);
    this.interactiveLayers.start();
    console.log("start scene: " + this.name);
  }

  stop(nextScene = undefined) {
    this.interactiveLayers.stop();
    this.removeCanvasElement();
    this.scene_graph_node.listener.removeAudioAmbiance(this.audioAmbiance, nextScene?.audioAmbiance);
    this.removeTemporary();
  }

  graphStopped(nextPath = undefined) {}

  getResourcePath() {
    return this.json.resourcePath || this.scene_graph.json.resourcePath;
  }

  set_visual() {
    this.removeCanvasElement();
    var resource_path = this.getResourcePath();
    this.visual_resource = this.scene_graph.resources.requestResourceFromJson(this.json,resource_path,this.json.resourcePath2 );

    if (this.visual_resource) {
      this.canvasElement = this.visual_resource.getCanvasElement();
      this.canvasElement.invalidate();
      this.icanvas.addElement(this.canvasElement);
    }
  }

  removeCanvasElement() {
    if (this.canvasElement) {
      this.icanvas.removeElement(this.canvasElement);
      this.canvasElement = undefined;
    }
  }

  toRect() {
    if (!this.visual_resource) {
      if (this.scene_graph_node) {
        return this.scene_graph_node.deriveRectForScene(this);
      }

      return new c2.Rect(0, 0, 0, 0);
    }
    return this.visual_resource.toRect(this.scene_graph.icanvas);
  }

  getScreenSpaceAreaRect() {
    return this.toRect();
  }

  drawFrame(icanvas) {
    if (this.visual_resource?.isLoading()) {
      return;
    }

    this.interactiveLayers.drawFrame(icanvas);
  }
  mousedown(ievent) {
    this.interactiveLayers.mousedown(ievent);
  }
  mouseup(ievent) {
    this.interactiveLayers.mouseup(ievent);
  }
  mousemove(ievent) {
    this.interactiveLayers.mousemove(ievent);
  }
  keypress(ievent) {
    this.interactiveLayers.keypress(ievent);
  }

  onTouchTap(ievent) {
    this.interactiveLayers.onTouchTap(ievent);
  }
  onTouchPan(ievent) {
    this.interactiveLayers.onTouchPan(ievent);
  }
  onTouchSwipe(ievent) {
    this.interactiveLayers.onTouchSwipe(ievent);
  }
  onTouchDistance(ievent) {
    this.interactiveLayers.onTouchDistance(ievent);
  }
  onTouchRotate(ievent) {
    this.interactiveLayers.onTouchRotate(ievent);
  }
  onTouchGesture(ievent) {
    this.interactiveLayers.onTouchGesture(ievent);
  }

  file_dropped(ievent) {
    this.interactiveLayers.file_dropped(ievent);
  }
  drag_file(ievent) {
    this.interactiveLayers.drag_file(ievent);
  }
  static getNameFromFilename(file) {
    return file.name;
  }
  static newJsonFromFile(file) {
    return {};
  }

  addInteractive(name, json, isTemporary = false) {
    return this.interactiveLayers.default_layer.addInteractive(name, json, isTemporary);
  }
  removeInteractive(name) {
    this.interactiveLayers.default_layer.removeInteractive(name);
  }

  findInteractiveByName(name, isTemporary = false) {
    return this.interactiveLayers.default_layer.findInteractiveByName(name, isTemporary);
  }

  convertEventWithPointToRelativePoint(e) {
    var asMouse = { x: e.offsetX, y: e.offsetY };

    var rect = this.toRect();

    var result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

    return result;
  }
}
