//https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro
export function htmlToElement(html) {
  var template = document.createElement("template");
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}
export function htmlToElements(html) {
  var template = document.createElement("template");
  template.innerHTML = html;
  return template.content.childNodes;
}

export function BorderlessTable() {
  var result = document.createElement("table");
  result = Object.assign(result, { cellspacing: 0, cellpadding: 0 });
  return result;
}

export function addTableRow(table, elements) {
  var row = document.createElement("tr");

  elements.forEach((element) => {
    let col = Object.assign(document.createElement("td"), {});
   // col.style.padding = "2px";
    col.appendChild(element);
    row.appendChild(col);
  });
  table.appendChild(row);
}

export function withBorderlessTableRow(elements) {
  var result = BorderlessTable();
  addTableRow(result, elements);
  return result;
}
