import { CachedAction } from "../cachedAction";

export class ConnectedStem {
  stemReferences = [];
  resource;
  audioSrc;
  sourceNode;
  blocked_actions = [];
  get sourceMedia() {
    return this.sourceNode.mediaElement;
  }
  gainNode;
  audio;
  //onUnloaded;
  gain;
  isLoop;
  isConnected;
  playCount;
  isPlaying;

  onDone;

  get firstStem() {
    return this.stemReferences[0];
  }
  get firstStemData() {
    return this.firstStem.json;
  }
  get resources() {
    return this.audio.resources;
  }

  constructor(audio, audioSrc) {
    this.audio = audio;
    this.audioSrc = audioSrc;
    this.playCount = 0;
  }

  addReference(stem) {
    this.stemReferences.push(stem);
    if (!this.resource) {
      this.loadFrom(stem);
    }
  }
  removeReference(stem) {
    const index = this.stemReferences.indexOf(stem);
    if (index > -1) {
      this.stemReferences.splice(index, 1);
      //this.unloadFrom(stem);
    }
  }
  isUnreferenced() {
    if (this.stemReferences.length == 0) {
      return true;
      // this.dispose();
      // if (this.onUnloaded) {
      //   this.onUnloaded();
      // }
    }
    return false;
  }

  useSourceNode(callback) {
    if (!this.resource) {
      return;
    }
    let loading = this.resource.isLoading();

    if (loading) {
      let self = this;

      var and_then = (res) => {
        try {
          callback(self.sourceNode);
        } catch (e) {
          console.log("audio exception");
        }
      };

      this.resource.addLoadingThen(and_then);
    } else {
      callback(this.sourceNode);
    }
  }
  loadFrom(stem) {
    if (this.resource !== undefined) {
      throw new Error("invalid operation - connected stem can load once");
    }

    this.isLoop = stem.json.isLoop;
    this.gain = stem.json.gain;
    this.resource = this.resources.requestResource(this.audioSrc, "audio", stem.getResourcePath(),stem.json.resourcePath2);

    var isLoading = this.resource.isLoading();
    if (isLoading) {
      let self = this;
      var and_then = (loading_cxt) => {
        self.createNodeFromResource();
      };
      this.resource.addLoadingThen(and_then);
    } else {
      this.createNodeFromResource();
    }
  }

  unloadFrom(stem) {}

  playFrom(stem) {
    this.playCount = this.playCount + 1;

    if (this.playCount > 1) {
      return;
    }

    var cxt = this.newPlayContextFromStem(stem);
    this.play(cxt);
  }
  stopFrom(stem) {
    this.playCount = this.playCount - 1;

    if (this.playCount > 0) {
      return;
    }
    var cxt = this.newStopContextFromStem(stem);
    this.stop(cxt);
  }

  setGainFrom(stem, amount) {
    this.setGain(amount);
  }

  startAudioOnGesture(now) {
    if (this.resource === undefined || this.resource.isLoading()) {
      return;
    }

    if (this.blocked_actions.length == 0) {
      return;
    }

    var latest = this.blocked_actions[this.blocked_actions.length - 1];
    if (latest.name == "stop") {
      return;
    }

    if (latest.name == "play") {
      this.blocked_actions = [];

      let delayed_context = { ...latest.context };

      delayed_context.startAtSeconds += now - latest.now;

      if (delayed_context.isLoop) {
        var duration = this.resource.resource_element.duration;

        if (delayed_context.startAtSeconds > duration) {
          delayed_context.startAtSeconds = delayed_context.startAtSeconds % duration;
        }
      }

      this.play(delayed_context);
    }
  }

  static default_play_cxt = { startAtSeconds: 0, isLoop: false, gain: undefined };

  newPlayContextFromStem(stem) {
    let result = { ...ConnectedStem.default_play_cxt };
    result.isLoop = stem.json.isLoop;
    return result;
  }

  play(play_cxt = ConnectedStem.default_play_cxt) {
    play_cxt = Object.assign({}, ConnectedStem.default_play_cxt, play_cxt);
    var self = this;
    this.useSourceNode(() => {
      if (!self.isConnected) {
        self.audio.connectNodeToLayer(self.firstStemData.layer, self.gainNode);
        self.isConnected = true;
      }

      try {
        if (play_cxt.gain !== undefined) {
          this.setGain(play_cxt.gain);
        }

        var message = `audio: play(gain:${this.gain}) - ${self.name || self.audioSrc}`;
        console.log(message);

        var play_promise = self.sourceMedia.play();

        if (play_promise !== undefined) {
          play_promise
            .then(() => {
              self.isPlaying = true;
              if (play_cxt.startAtSeconds != 0) {
                self.sourceMedia.currentTime = play_cxt.startAtSeconds;
              }
            })
            .catch((error) => {
              if (error.name === "NotAllowedError") {
                console.log("audio delay:" + error);
                self.blocked_actions.push(new CachedAction("play", play_cxt, Math.floor(Date.now() / 1000)));
              } else {
                console.log("audio error:"+self.sourceMedia.currentSrc+"\n" + error);
              }
            });
        }
      } catch (e) {
        console.log("blocked:" + message);
      }
    });
  }

  static default_stop_cxt = { isPause: false };

  newStopContextFromStem(stem) {
    let result = { ...ConnectedStem.default_stop_cxt };

    return result;
  }

  stop(stop_cxt = ConnectedStem.default_stop_cxt) {
    stop_cxt = Object.assign({}, ConnectedStem.default_stop_cxt, stop_cxt);
    var self = this;
    this.useSourceNode(() => {
      self.gainNode.disconnect();
      self.isConnected = false;

      var name = stop_cxt.isPause ? "pause" : "stop";
      var message = `audio: ${name} - ${self.name || self.audioSrc}`;
      console.log(message);
      try {
        var media_promise = self.sourceMedia.pause();

        if (media_promise !== undefined) {
          media_promise
            .then(() => {
              self.isPlaying = false;
              if (stop_cxt.isPause) {
                self.sourceMedia.currentTime = 0;
              }
            })
            .catch((error) => {
              if (error.name === "NotAllowedError") {
                console.log("audio delay:" + error);
                self.blocked_actions.push(new CachedAction("stop", stop_cxt, Math.floor(Date.now() / 1000)));
              } else {
                console.log("audio error:" + error);
              }
            });
        }
      } catch (e) {
        console.log("blocked:" + message);
      }
    });
  }

  pause() {
    this.stop({ isPause: true });
  }
  createNodeFromResource() {
    this.resource.resource_element.loop = this.isLoop;
    this.sourceNode = this.audio.audioContext.createMediaElementSource(this.resource.resource_element);
    this.sourceNode.mediaElement.addEventListener("ended", () => {
      if(this.onDone){
        this.onDone();
      }
      this.dispose();
    });
    this.gainNode = this.audio.audioContext.createGain();
    this.setGain(this.gain);
    this.sourceNode.connect(this.gainNode);
  }
  setGain(amount, isLog = false) {
    this.gain = amount;
    this.gainNode.gain.setValueAtTime(amount, this.audio.audioContext.currentTime);
    if (isLog) {
      var message = `audio: update(gain:${this.gain}) - ${this.name || this.audioSrc}`;
      console.log(message);
    }
  }
  // connectNode() {
  //   //this.gainNode.gain.setValueAtTime(this.listenerScope.gain, audio.audioContext.currentTime);
  // }

  dispose() {
    if (this.sourceNode) {
      this.sourceNode.disconnect();
      this.sourceNode = undefined;

      this.gainNode.disconnect();
      this.gainNode = undefined;
    }

    if (this.resource) {
      this.resources.disposeResource(this.resource);
      this.resource = undefined;
    }
  }
}
