import { CanvasElement } from "./display/CanvasElement.js";
import { InteractiveMobileCanvas } from "./interactive_mobile_canvas.js";
import { UpdateContext } from "./update.js";
import { MouseEvent } from "./MouseEvent.js";
import { DrawScope } from "./DrawScope.js";
import { CanvasStack } from "./display/canvasStack.js";

import $ from "jquery";
import "jquery-ui";
import "jquery-ui-css";
import { dialogOptions, id } from "./modules/ExternalModules.js";

let c2 = require("c2.js");
export class InteractiveCanvas {
	static viewportId = "viewport";
	viewport;
	canvas;
	ctx;
	components = [];
	window_listeners = {};
	update_context;
	drawScope = DrawScope.Normal;
	elements = [];
	screenElement;
	mobileCanvas;
	canvasStack;
	externalModules;
	isInputDisabled;
	isInputEnabledMilliseconds;
	isMobile;
	constructor() {
		this.intervals = [];
		this.canvasStack = new CanvasStack(this);
	}

	disableInput() {
		this.isInputDisabled = true;
	}
	enableInput() {
		this.isInputDisabled = false;
		this.isInputEnabledMilliseconds = Date.now();
	}
	deactivate() {
		this.viewport.style.display = "none";
	}

	reactivate() {
		this.viewport.style.display = "block";
	}

	initialize() {
		window.addEventListener("resize", () => {
			// e.preventDefault();
			// e.stopPropagation();
			this.resize();
		});

		this.window_listeners.mousedown = (e) => this.mousedown(e);
		this.window_listeners.mouseup = (e) => this.mouseup(e);
		this.window_listeners.mousemove = (e) => this.mousemove(e);

		this.isMobile = InteractiveMobileCanvas.isMobile();
		if (InteractiveMobileCanvas.isTouchCapable()) {
			this.mobileCanvas = new InteractiveMobileCanvas(this);
		}

		this.window_listeners.keypress = (e) => this.keypress(e);

		for (const [key, value] of Object.entries(this.window_listeners)) {
			window.addEventListener(key, value);
		}

		this.viewport = document.getElementById(InteractiveCanvas.viewportId);

		// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/drop_event
		//
		this.viewport.ondrop = (event) => {
			this.drop_file(event);
		};

		this.viewport.ondragover = (event) => {
			this.drag_file(event);
		};

		this.canvas = document.getElementById("viewport_canvas");
		this.ctx = this.canvas.getContext("2d");
		this.updateCanvasSize(false);

		this.setupUpdate();
		this.screenElement = this.addElement(new CanvasElement());

		this.mobileCanvas?.initialize();
	}

	uninitialize() {
		this.mobileCanvas?.uninitialize();
		window.removeEventListener("resize", this.resize);
		for (const [key, value] of Object.entries(this.window_listeners)) {
			window.removeEventListener(key, value);
		}
		this.intervals.forEach((each) => clearInterval(each));
		this.removeElement(this.screenElement);
	}

	addElement(canvasElement) {
		this.elements.push(canvasElement);
		canvasElement.initialize(this);
		return canvasElement;
	}

	removeElement(canvasElement) {
		let index = this.elements.indexOf(canvasElement);
		if (index >= 0) {
			this.elements[index].uninitialize(this);
			this.elements.splice(index, 1);
		}
	}

	setupUpdate() {
		this.update_context = new UpdateContext();
		this.update_context.start_time = Date.now();
		this.update_context.time = this.update_context.start_time;
		this.update_context.delta = this.update_context.time - this.update_context.start_time;
	}
	updateCanvasSize(notifyElements = true) {
		this.canvas.width = this.viewport.clientWidth;
		this.canvas.height = this.viewport.clientHeight;
		if (notifyElements) {
			this.elements.forEach((v) => v.onCanvasResized(this));
		}
	}

	toRect() {
		var result = new c2.Rect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
		return result;
	}
	convertEventWithPointToRelativePoint(e) {
		var asMouse = { x: e.offsetX, y: e.offsetY };

		var rect = this.toRect();

		var result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

		return result;
	}

	// update() {
	//   var now = Date.now();
	//   var delta = now - this.update_context.time;

	//   this.update_context.time = now;
	//   this.update_context.delta = delta;
	//   this.update_context.isDrawFrame = false;

	//   this.components.forEach((v) => v?.update(this));

	//   if (this.update_context.isDrawFrame) {
	//     this.drawFrame();
	//   }
	// }

	ClearScreen() {
		this.ctx.clearRect(0, 0, this.ctx.canvas.clientWidth, this.ctx.canvas.clientHeight);
	}

	addComponent(c) {
		this.components.push(c);
	}

	drawFrame() {
		this.ClearScreen();
		if (this.externalModules.open_modules.length == 0) {
			this.elements.sort((a, b) => (a.draw_order > b.draw_order ? 1 : -1));
		}
		this.elements.sort((a, b) => (a.draw_order > b.draw_order) ? 1 : -1);

		this.elements.forEach((v) => {
			if (!v.isHidden) {
				v.draw(this);
			}
		});
		this.components.forEach((v) => v.drawFrame(this));
	}

	try_invalidated_draw() {
		let isDraw = false;

		//let loadingPromise;

		for (let eachElement in this.elements) {
			let element = this.elements[eachElement];

			if (element.is_invalidating_draw) {
				// if (element.isDependencyLoading()) {
				//   if (!loadingPromise) {
				//     loadingPromise = element.getFirstDependencyLoadingPromise();
				//   }
				// } else
				if (element.isLoading()) {
					// if (!loadingPromise) {
					//   loadingPromise = element.getFirstLoadingPromise();
					// }
					var loadingPromise = element.getFirstLoadingPromise();
					loadingPromise?.then(() => {
						this.try_invalidated_draw();
					});
				} else {
					isDraw = true;
					element.validate(this);
				}
			}
		}

		if (isDraw) {
			// console.log("canvas draw");
			this.drawFrame();
		} else {
			// console.log("try canvas draw");
		}

		// loadingPromise?.then(() => {
		//   this.try_invalidated_draw();
		// });
	}

	isCanvasEvent(e) {
		return e.target.nodeName == "CANVAS" || e.target.nodeName == "VIDEO";
	}
	mousedown(e) {
		if (!this.isCanvasEvent(e) || this.isInputDisabled) {
			return;
		}
		let relative_e = new MouseEvent(e);
		for (let each in this.components) {
			this.components[each].mousedown(this, relative_e);
		}

		// this.try_invalidated_draw();
	}

	keypress(e) {
		if (this.isInputDisabled) {
			return;
		}

		for (let each in this.components) {
			this.components[each].keypress(this, e);
		}

		this.try_invalidated_draw();
	}

	mouseup(e) {
		// hack: compare when the input was enabled within a millisecond to now to prevent jquery ui dialog resize from registering a mouseup interaction.
		if (!this.isCanvasEvent(e) || this.isInputDisabled || this.isInputEnabledMilliseconds + 3 >= Date.now()) {
			return;
		}
		// console.log("mup " + this.isInputEnabledMilliseconds + " " + Date.now());
		let relative_e = new MouseEvent(e);
		for (let each in this.components) {
			this.components[each].mouseup(this, relative_e);
		}
		this.try_invalidated_draw();
	}

	mousemove(e) {
		if (!this.isCanvasEvent(e) || this.isInputDisabled) {
			return;
		}
		let relative_e = new MouseEvent(e);
		for (let i = 0; i < this.components.length; i++) {
			this.components[i].mousemove(this, relative_e);
		}
		//  this.try_invalidated_draw();
	}

	get_width() {
		return this.canvas.clientWidth;
	}
	get_height() {
		return this.canvas.clientHeight;
	}
	resize() {
		this.updateCanvasSize();
		this.drawFrame();
		dialogOptions.width = this.elements[1].width;
		dialogOptions.height = this.elements[1].height;
		$(`#${id}`).dialog(dialogOptions);
	}

	start() {
		for (let each in this.components) {
			this.components[each].start();
		}
		this.updateCanvasSize();

		this.try_invalidated_draw();
	}

	draw_point(shape, radius = 3, drawScope = DrawScope.Normal) {
		if (this.drawScope < drawScope) {
			return;
		}
		this.ctx.beginPath();
		this.ctx.fillStyle = "white";
		this.ctx.arc(shape.x, shape.y, radius, 0, 2 * Math.PI, true);
		this.ctx.fill();
	}
	draw_rect(shape, drawScope = DrawScope.Normal) {
		if (this.drawScope < drawScope) {
			return;
		}
		this.ctx.beginPath();
		this.ctx.lineWidth = "2";
		this.ctx.strokeStyle = "white";
		this.ctx.rect(shape.p.x, shape.p.y, shape.w, shape.h);
		this.ctx.stroke();
	}
	draw_text(string, position, size = 15, drawScope = DrawScope.Normal) {
		if (this.drawScope < drawScope) {
			return;
		}
		this.ctx.font = size + "px Georgia";
		this.ctx.fillStyle = "white";
		this.ctx.fillText(string, position.x, position.y);
	}
	move_point_up(point, amount) {
		let result = point.copy();
		result.y -= amount;
		return result;
	}
	invalidate() {
		//this.screenElement.invalidate();

		for (let eachElement in this.elements) {
			let element = this.elements[eachElement];
			element.invalidate();
		}
	}
	invaidate() {
		this.invalidate();
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop

	collectFilesFromDragdropEvent(ev) {
		var files = [];

		if (ev.dataTransfer.items) {
			[...ev.dataTransfer.items].forEach((item, i) => {
				if (item.kind === "file") {
					const file = item.getAsFile();
					files.push({ file: file, dataTransfer: item });
				}
			});
		} else {
			[...ev.dataTransfer.files].forEach((file, i) => {
				files.push({ file: file });
			});
		}
		return files;
	}

	drop_file(ev) {
		ev.preventDefault();

		var files = this.collectFilesFromDragdropEvent(ev);

		this.file_dropped(ev, files);
		this.try_invalidated_draw();
	}

	drag_file(ev) {
		ev.preventDefault();

		var files = this.collectFilesFromDragdropEvent(ev);

		this.file_dragged(ev, files);
		this.try_invalidated_draw();
	}

	file_dropped(e, files) {
		for (let i in this.components) {
			var each = this.components[i];
			if (each.file_dropped) {
				each.file_dropped(e, files);
			}
		}
	}

	file_dragged(e, files) {
		for (let i in this.components) {
			var each = this.components[i];
			if (each.drag_file) {
				each.drag_file(e, files);
			}
		}
	}

	activate(value, value_context) {
		if (value == "interactive.input" && value_context?.keypress) {
			let e = {};
			e.key = value_context.keypress;
			this.keypress(e);
			return;
		}

		let event = new InteractiveEvent();
		event.activate_value = value;
		event.activate_value_context = value_context;

		for (var each = this.components.length - 1; each >= 0; each--) {
			let c = this.components[each];
			if (!c.activate) {
				continue;
			}
			c.activate(event);
			if (event.isStopPropagation) {
				break;
			}
		}
	}
}
