import { Resources } from "./resources.js";
import { Simulation } from "./simulation.js";
import { InteractiveCanvas } from "./interactive_canvas.js";
import { WebBrowserClient } from "./webBrowserClient.js";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface.js";
import { WebAudio } from "./audio/webAudio.js";
import { ExternalModules } from './modules/ExternalModules.js';
import { InternalModules } from './modules/internalModules.js';
import { SceneModules } from './modules/sceneModules.js';

let versionNumber = "0.2.2";


export async function startClient(state, newWebApplication, newServer) {
	state.libraryVersionNumber=versionNumber;
	state.resources = new Resources();
	await state.resources.Initialize();

	state.application = newWebApplication(state);
	state.application.initialize();

	console.log(`Hello ${state.application.name}!`);

	state.server = newServer(state);
	await state.server.initialize();

	state.browser = new WebBrowserClient(state.resources, state.application, state.server);
	state.browser.initialize();
	state.server.setBrowser(state.browser);

	state.account = await state.server.getCustomerAccount();
	state.account.initialize();
	state.server.setClientCustomerAccount(state.account);

	await state.server.initialize2();

	state.canvas = new InteractiveCanvas();
	state.canvas.initialize();

	state.audio = new WebAudio(state.resources, state.account);
	state.audio.initialize();
	state.canvas.addComponent(state.audio);

	state.simulation = new Simulation(state.resources, state.canvas, state.account, state.server, state.audio);
	await state.simulation.initialize();
	state.canvas.addComponent(state.simulation);

	state.externalModules = new ExternalModules(state.resources, state.canvas, state.account, state.server, state.audio);
	state.application.initializeExternalModules(state);
	state.externalModules.initialize();
	state.internalModules = new InternalModules(state.externalModules);
	state.application.initializeInternalModules(state);
	state.internalModules.initialize();
	state.sceneModules = new SceneModules();
	state.application.initializeSceneModules(state);
	state.sceneModules.initialize();

	state.author = new AuthorInterface(state.resources, state.canvas, state.account);

	state.author.components.push(state.browser);
	state.author.components.push(state.application);
	state.author.components.push(state.audio);
	state.author.components.push(state.account);
	state.author.components.push(state.simulation);
	state.author.components.push(state.server);
	state.author.components.push(state.externalModules);
	state.author.components.push(state.internalModules);
	state.author.components.push(state.sceneModules);

	state.externalModules.authoringInterface = state.author;
	state.internalModules.authoringInterface = state.author;
	state.sceneModules.authoringInterface = state.author;

	state.author.initialize();
	state.canvas.addComponent(state.author);

	document.addEventListener("visibilitychange", () => {
		if (document.visibilityState == "hidden") {
			state.saveState();
		}
	});
	
	await state.canvas.start();
}

