export const DataLocation = {
  None: 1,
  Unknown: 2,
  Client: 3,
  Cloud: 4,
};

export class WebApplicationServer {
  resources;
  name;
  json;
  browser;
  account;
  app;
  get application(){
    return this.app;
  }
  get url(){
    return window.location.href;
  }
  get pathname(){
    return window.location.pathname;
  }
  get search(){
    return window.location.search;
  }
  get hash(){
    return window.location.hash;
  }
  constructor(resources, app, name = "") {
    this.resources = resources;
    this.name = name;
    this.app = app;
  }

  stroageItemName(append_extension = true) {
    return this.resources.combineJsonResourceNames([this.name, "application.server.storage"], append_extension);
  }

  setClientCustomerAccount(account) {
    this.account = account;
	this.name = account.firstName;
  }
  async initialize() {
    this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
  }
  async initialize2() {
  }
  saveState() {
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  shutdown() {
    this.saveState();
  }
  setBrowser(v) {
    this.browser = v;
  }
}
