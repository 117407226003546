import { AudioAmbianceSet } from "../audio/audioAmbianceSet.js";
import { Interactive } from "./Interactive.js";
import { Scene } from "./scene.js";
import Graph from "graphology";
import { SceneObject } from "./scene_objects.js";
import { ScenePath } from "./ScenePath.js";
import { InteractiveLayer, InteractiveLayerJson } from "./InteractiveLayer.js";

export class SceneGraph {
  simulation;
  get resources() {
    return this.simulation.resources;
  }
  get icanvas() {
    return this.simulation.icanvas;
  }
  get sceneGraphSet() {
    return this.simulation.sceneGraphSet;
  }

  get account() {
    return this.simulation.account;
  }
  path;
  json;
  graph;
  //graph_node;
  sceneItemsToPush = [];
  parentSceneGraph;
  sceneGraphIndex;
  audioAmbiance;

  constructor(simulation, json, parentSceneGraph = undefined, path = undefined, sceneGraphIndex = undefined) {
    this.simulation = simulation;
    this.parentSceneGraph = parentSceneGraph;
    this.json = json;
    this.path = path;
    this.sceneGraphIndex = sceneGraphIndex;

    if (!this.json.resourcePath) {
      this.json.resourcePath = this.name;
    }
    this.graph = new Graph();
    this.initializeGraphFromJson();
    this.initializeCommandsFromJson();

    this.pushSceneItems(this.sceneItemsToPush);
    if (this.parentSceneGraph) {
      this.pushSceneItems(this.parentSceneGraph.sceneItemsToPush);
    }
    this.audioAmbiance = new AudioAmbianceSet(this.name, simulation.audio);
    
    for (const { node, attributes } of this.graph.nodeEntries()) {
      if (attributes.scene.audioAmbiance) {
        this.audioAmbiance.addAmbiance(attributes.scene.audioAmbiance);
      }
    }
  }

  collectScenes(list) {
    for (const { node, attributes } of this.graph.nodeEntries()) {
      list.push(attributes.scene);
    }
  }

  pushSceneItems(from) {
    for (const eachItem in from) {
      this.graph.forEachNode((node, attributes) => {
        let eachScene = attributes.scene;
        let item = from[eachItem];
        item = item.clone();
        eachScene.push(item);
      });
    }
  }
  get name() {
    return this.json.name;
  }
  get version() {
    return this.json.version;
  }
  get defaultScene() {
    return this.json.defaultScene;
  }
  get defaultOrFirstScene() {
    var name = this.json.defaultScene;

    var found = this.findSceneByName(name);

    if (!found) {
      found = this.findFirstScene();
    }
    return found;
  }
  get defaultSceneGraph() {
    return this.json.defaultSceneGraph;
  }

  addScene(name, json, isTemporary = false) {
    var s = new Scene(this, name, json);
    this.graph.addNode(s.name, { scene: s, isTemporary: isTemporary });
  }
  removeScene(name) {
    var node = this.graph.nodes()[name];
    this.graph.dropNode(node);
  }
  initializeGraphFromJson() {
    for (const key in this.json) {
      var value = this.json[key];
      if (key == "scenes") {
        for (const sceneskey in value) {
          this.addScene(sceneskey, value[sceneskey]);
        }
      }
    }
  }
  toPath(scene_name = undefined) {
    return new ScenePath(this.name, scene_name);
  }
  toPathWithDefaultScene() {
    return this.toPath(this.defaultScene);
  }

  initializeCommandsFromJson() {
    this.json.sceneCommon?.interactiveLayers?.forEach((element) => {
      this.sceneItemsToPush.push(new InteractiveLayerJson(element));
    });
    this.json.sceneCommon?.interactives?.forEach((element) => {
      this.sceneItemsToPush.push(new Interactive(element));
    });
    this.json.sceneCommon?.sceneObjects?.forEach((element) => {
      this.sceneItemsToPush.push(new SceneObject(element));
    });
  }

  findSceneByPath(path, isTemporary = false) {
    return this.findSceneByName(path.sceneName, isTemporary);
  }

  findSceneByName(name, isTemporary = false) {


    for (const { node, attributes } of this.graph.nodeEntries()) {
      if (node == name && attributes.isTemporary == isTemporary) {
        return attributes.scene;
      }
    }
  }
  findFirstScene(isTemporary = false) {
    for (const { node, attributes } of this.graph.nodeEntries()) {
      if (attributes.isTemporary == isTemporary) {
        return attributes.scene;
      }
    }
  }
}
