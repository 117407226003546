import { AssetDatabase, IndexedDBAssetDataInterface } from "./server/shared/AssetDatabase";
import { Treeview } from "./view/treeview";

export class LocalServerFileCache {
  server;
  server_asset_list;
  server_asset_list_promise;
  db_promise;
  db_upgrade_promise;
  db;
  db_version;
  asset_database;
  application;

  constructor(server, application) {
    this.server = server;
    this.application=application;
    this.asset_database = new AssetDatabase();
  }

  collectEditableProperties(layout) {
    if (this.server_asset_list) {
      let server_asset_list_view = new Treeview();
      server_asset_list_view.buildElementFromJson(this.server_asset_list, 1);
      layout.add(server_asset_list_view.element);
    }
  }

  createNewDatabase(database, resolve) {
    database.createObjectStore(AssetDatabase.storeName, { keyPath: "path" /*, autoIncrement: true*/ });
    resolve();
  }
  async initialize() {
    self = this;

    var fromLocalStorage = this.server.resources.getLocalStorageJsonItem(this.stroageItemName());
    this.asset_database.loadFromJson(fromLocalStorage);

    this.db_promise = window.indexedDB.open(AssetDatabase.dbName, 5);

    this.db_promise.onerror = (event) => {};

    this.db_promise.onsuccess = (event) => {
      self.db = self.db_promise.result;
    };

    this.db_promise.onupgradeneeded = (event) => {
      this.db_upgrade_promise = new Promise((resolve) => {
        let db = event.target.result;

        if (db.objectStoreNames.contains(AssetDatabase.storeName)) {
          db.deleteObjectStore(AssetDatabase.storeName);
          self.createNewDatabase(db, resolve);
        } else {
          self.createNewDatabase(db, resolve);
        }
      });
    };

    this.server_asset_list_promise = new Promise((resolve) => {
      $.get("/assets/list", function (data, status) {
        self.server_asset_list = data;
        resolve(status);
      }).fail(function (xhr, status, error) {
        resolve(status);
      });
    });
  }

  stroageItemName() {
    return this.server.resources.combineJsonResourceName(this.server.application.name, "server_file.storage");
  }
  saveState() {
    //this.asset_database.json.db_version = this.db.version;
    var asset_db = this.asset_database.saveToJson();
    //asset_db={};
    this.server.resources.setLocalStorageItemAsJson(this.stroageItemName(), asset_db);
  }
  shutdown() {
    this.saveState();
  }

  async initialize2() {
    await this.db_promise;
    if (this.db_upgrade_promise) {
      await this.db_upgrade_promise;
    }
    await this.server_asset_list_promise;

    if (this.db) {
      this.asset_database.setDataInterface(new IndexedDBAssetDataInterface(this.db));
    }

    if (this.application.getSetting("isPreCacheAssets") && this.server_asset_list && this.db) {
      await this.asset_database.downloadNewAssets(this.server_asset_list);
    }
  }

  loadFromCacheOrUrl(assetObject, url) {
    var is_enabled=this.application.getSetting("isPreCacheAssets");
    this.asset_database.loadFromCacheOrUrl(assetObject, url,is_enabled);
  }
}
