import { WebApplicationServer } from "./webApplicationServer";
import { CustomerAccount } from "./customerAccount";
import { StackLayout } from "./view/stackLayout";
import { LocalServerFileCache } from "./LocalServerFileCache";

export class LakesideCabinServer extends WebApplicationServer {
  static typeName = "lakeside_cabin";
  json;
  server_file_cache;

  constructor(resources, app, name = "") {
    super(resources, app, name);
    resources.server=this;
    this.server_file_cache = new LocalServerFileCache(this,app);
  }

  getCustomerNameFromUrl() {
    if (this.pathname == "/") {
      return "guest";
    }

    let slash_count = this.pathname.match("/").length;

    if (slash_count == 1) {
      const regex = /^\/([\w\W]+)$/;
      const matches = this.pathname.match(regex);

      if (matches?.length == 2) {
        return matches[1];
      }
    }
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(
      this.app.name,
      LakesideCabinServer.typeName,
      super.stroageItemName(false)
    );
  }

  async initialize() {
    await super.initialize();
    this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());


    if(this.app.getSetting("isUseREST") && this.app.getSetting("isPreCacheAssets")){
      await this.server_file_cache.initialize();
    }
  }
  async initialize2() {
    super.initialize2();
    if(this.app.getSetting("isUseREST") &&this.app.getSetting("isPreCacheAssets")){
      await this.server_file_cache.initialize2();
    }
  }

  saveState() {
    super.saveState();
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    this.server_file_cache.saveState();
  }
  shutdown() {
    super.shutdown();
    this.saveState();
    this.server_file_cache.shutdown();
  }

  async updateCustomerAccount(account_name, data) {

    if(!this.app.getSetting("isUseREST")){
      return Promise.resolve();
    }

    let promise = new Promise((resolve) => {
      $.post("/accounts/" + account_name, data, function (data, status) {
        resolve(status);
      }).fail(function (xhr, status, error) {
        resolve(status);
      });
    });

    return promise;
  }

  async getCustomerAccount(account_name = undefined) {
    if (account_name == undefined) {
      account_name = this.getCustomerNameFromUrl();
    }

    let self = this;
    let super_resources = this.resources;
    let super_app = this.app;


    if(!this.app.getSetting("isUseREST")){
      let result = CustomerAccount.fromGuest(super_resources, super_app, self);
      return Promise.resolve(result);
    }    

    let promise = new Promise((resolve) => {
      $.get("/accounts/" + account_name, function (data, status) {
        let result = CustomerAccount.fromServerData(super_resources, super_app, self, data);

        resolve(result);
      }).fail(function (xhr, status, error) {
        let result = CustomerAccount.fromGuest(super_resources, super_app, self);

        resolve(result);
      });
    });

    return promise;
  }

  collectEditableProperties(layout) {
    this.server_file_cache.collectEditableProperties(layout);
  }
  getAuthorInterfaceName() {
    return "server";
  }
  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    return layout.element;
  }

  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, false);
  }
}
