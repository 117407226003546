import { Action } from "./action";
import { ActivateAction } from "../sceneActions/ActivateAction";
import { BackAction } from "../sceneActions/BackAction";
import { NavigateAction } from "../sceneActions/NavigateAction";
import { NavigateHomeAction } from "../sceneActions/NavigateHomeAction";
import { OpenAreaSizedModuleAction } from "../sceneActions/OpenAreaSizedModuleAction";
import { OpenFullscreenModuleAction } from "../sceneActions/OpenFullscreenModuleAction";
import { OpenSceneSizedModuleAction } from '../sceneActions/OpenSceneSizedModuleAction';
import { ToggleAction } from "../sceneActions/ToggleAction";
import { PlayAudioAction } from '../sceneActions/playAudio';
export class Actions {
  static typename = "actions";
}
export function newActionFromJson(json) {
  var verb = json.verb;
  var name = json.name;
  var obj;
  if (verb == "toggle") {
    obj = new ToggleAction(verb, name, json);
  } else if (verb == "navigate") {
    obj = new NavigateAction(verb, name, json);
  } else if (verb == "back") {
    obj = new BackAction(verb, name, json);
  } else if (verb == "home") {
    obj = new NavigateHomeAction(verb, name, json);
  } else if (verb == "activate") {
    obj = new ActivateAction(verb, name, json);
  } else if (verb == "openFullscreenModule") {
    obj = new OpenFullscreenModuleAction(verb, name, json);
  } else if (verb == "openAreaSizedModule") {
    obj = new OpenAreaSizedModuleAction(verb, name, json);
  } else if (verb == "openSceneSizedModule") {
    obj = new OpenSceneSizedModuleAction(verb, name, json);
  }else if (verb == "play audio") {
    obj = new PlayAudioAction(verb, name, json);
  } else {
    console.warn("unknown action" + verb);
    obj = new Action(verb, name);
  }
  return obj;
}
