import { startClient } from "../../cabin-library/client/src/main.js";
import { WebApplication } from "../../cabin-library/client/src/webApplication.js";
import { WebApplicationState } from "../../cabin-library/client/src/WebApplicationState.js";

import { LakesideCabinServer } from "../../cabin-library/client/src/LakesideCabinServer.js";
let versionNumber = "0.1.1";

class CardCraftWebApplication extends WebApplication {
  constructor(state) {
    super(state.resources, "Card Craft", state.clientVersionNumber);
  }
}

class CardCraftServer extends LakesideCabinServer {
  constructor(state) {
    super(state.resources, state.application);
  }
}

(async () => {
  let state = new WebApplicationState();
  state.clientVersionNumber=versionNumber;

  let newWebApplication = (withState) => {
    return new CardCraftWebApplication(withState);
  };

  let newServer = (withState) => {
    return new CardCraftServer(withState);
  };

  await startClient(state, newWebApplication, newServer);
})();
