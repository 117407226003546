export class SceneObject {
  static typename = "object";
  name;
  action_values = {};
  json;

  constructor(json) {
    this.json = json;
    this.parseJson();
  }
  clone(){
    return new SceneObject(this.json);
  }
  parseJson() {}
}

export class SceneObjects {
  scene;
  sceneObjects = [];
  constructor(scene) {
    this.scene = scene;
    this.initializeFromSceneJson();
  }
  initializeFromSceneJson() {}
}
