import { AudioListenerScope } from "../audio/AudioListenerScope";

export class ScenePath {
  sceneGraphName;
  sceneName;
  constructor(sceneGraphName = undefined, sceneName = undefined) {
    this.sceneGraphName = sceneGraphName;
    this.sceneName = sceneName;
  }
  toAudioScope() {
    return new AudioListenerScope(this.sceneGraphName, this.sceneName);
  }
}
