import { Treeview } from "../view/treeview";
import { v4 as uuidv4 } from "uuid";
import * as htmlHelper from "../htmlHelper";
import $ from "jquery";
import "jquery-ui";
import "jquery-ui-css";
import { BaseModules, ModulesCommon } from "./ModulesCommon";

export class SceneModules extends BaseModules {
  authoringInterface;
  json;
  constructor(json = {}) {
    super();
    this.json = json;
  }
  initialize() {}

  saveState() {
    // this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  shutdown() {
    this.saveState();
  }
}
