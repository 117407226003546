import { AudioListenerScope } from "../audio/AudioListenerScope";
import { AudioAmbianceSet } from "../audio/audioAmbianceSet";
import { AmbianceAudioListener } from "../audio/audioListener";
import { InteractiveEvent } from "./InteractiveEvent";
import { DragDropEvent } from "./InteractiveLayers";
let c2 = require("c2.js");

export class SceneGraphNode {
  scenes;
  simulation;
  listener;

  get firstScene() {
    return this.scenes[0];
  }
  get audio() {
    return this.simulation.audio;
  }
  get resources() {
    return this.simulation.resources;
  }
  get icanvas() {
    return this.simulation.icanvas;
  }

  constructor(simulation) {
    this.scenes = [];
    this.simulation = simulation;
  }

  initiaizeBegin() {
    var a = this.audio;
    this.listener = new AmbianceAudioListener(a);
    this.audio.onStartAudioOnGesture = () => {
      this.onStartAudioOnGesture();
    };
  }

  initiaizeEnd() {
    //this.listener.start();
    // this.sceneGraphNode.invalidate();
  }

  onStartAudioOnGesture() {
    this.listener?.onStartAudioOnGesture();
  }

  TryReplaceScene(scene_graph_set, old_path, new_path) {
    var found_old = this.findSceneIndexByPath(old_path);

    if (found_old == undefined) {
      console.error(`scene path not found in scene graph node ${JSON.stringify(found_old)}`);
      return false;
    }

    var found_new = new_path && scene_graph_set.getSceneGraphSceneByPath(new_path);

    if (found_new == undefined) {
      console.warn(`scene path not found in scene graph set ${JSON.stringify(new_path)}`);
      return false;
    }

    var scene = this.scenes[found_old];
    scene.stop(found_new);
    scene.scene_graph_node = undefined;

    found_new.scene_graph_node = this;
    found_new.start(scene);

    //if (found_old != undefined) {
    this.scenes[found_old] = found_new;

    //this.listener.scopeChanged(AudioListenerScope.fromScenePath(new_path), found_new.sceneGraph.audioAmbiance);

    return true;
    // } else {
    //   this.scenes.push(found_new);
    // }
    // } else {
    //   console.warn(`scene path not found ${JSON.stringify(new_path)}`);

    //   if (found_old != undefined) {
    //     this.scenes.splice(found_old, 1);
    //   }
    // }
  }

  addAndStart(scene, isSetScope = false) {
    scene.scene_graph_node = this;
    scene.start(undefined);
    this.scenes.push(scene);

    // this.listener.scopeChanged(
    //   isSetScope ? scene.toScenePath().toAudioScope() : undefined,
    //   scene.sceneGraph.audioAmbiance
    // );
  }

  tryAddAndStartScenePath(scene_graph_set, path, isSetScope = false) {
    var found = path && scene_graph_set.getSceneGraphSceneByPath(path);

    if (found == undefined) {
      console.warn(`scene path not found in scene graph set ${JSON.stringify(path)}`);
      return false;
    }

    this.addAndStart(found, isSetScope);
    return true;
  }

  invalidate() {
    this.scenes.sort((a, b) => (a.sceneLayerOrder > b.sceneLayerOrder ? 1 : -1));

    // if (found_old != undefined || found_new != undefined) {
    // this.refreshAudio();
    this.icanvas.invalidate();
    // }
  }

  // get audioAmbianceSet() {
  //   return this.audioAmbianceSet_cache;
  // }
  // set audioAmbianceSet(value) {
  //   if (this.audioAmbianceSet_cache) {
  //     this.audioAmbianceSet_cache.stop();
  //   }
  //   this.audioAmbianceSet_cache = value;

  //   this.audioAmbianceSet_cache?.start(this);
  // }

  // refreshAudio() {
  //   this.audio.leaveRegion("");
  //   var audio_set = new AudioAmbianceSet(this.audio);

  //   this.scenes.forEach((eachScene) => {
  //     for (const { node, attributes } of eachScene.sceneGraph.graph.nodeEntries()) {
  //       var eachGraphScene = attributes.scene;
  //       let eachAmbiance = eachGraphScene.audioAmbiance;

  //       if (eachAmbiance) {
  //         audio_set.addAmbiance(eachAmbiance);
  //       }
  //     }
  //   });
  //   this.audio.enterRegion("", audio_set);
  // }

  findSceneByPath(path) {
    return this.findSceneByName(path.sceneName);
  }
  findSceneIndexByPath(path) {
    if (!path) {
      return undefined;
    }
    for (let index = 0; index < this.scenes.length; index++) {
      const element = this.scenes[index];
      if (element.name == path.sceneName) {
        return index;
      }
    }
  }
  findSceneByName(name) {
    for (const scene of this.scenes) {
      if (scene.name == name) {
        return scene;
      }
    }
  }

  deriveRectForScene(scene) {
    for (let each in this.scenes) {
      let i = this.scenes[each];
      if (i === scene) {
        continue;
      }

      if (i.visual_resource && !i.visual_resource.isLoading()) {
        return i.toRect();
      }
    }
    var defaultW = 3840;
    var defaultH = 2160;

    var hRatio = this.simulation.icanvas.get_width() / defaultW;
    var vRatio = this.simulation.icanvas.get_height() / defaultH;
    var ratio = Math.min(hRatio, vRatio);
    var result = new c2.Rect(0, 0, defaultW * ratio, defaultH * ratio);
    return result;

    //  return this.simulation.icanvas.toRect();
  }

  drawFrame(icanvas) {
    for (let each in this.scenes) {
      let i = this.scenes[each];
      i.drawFrame(icanvas);
    }
  }

  mousedown(icanvas, e) {
    let event = new InteractiveEvent(icanvas, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].mousedown(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }

  mouseup(icanvas, e) {
    let event = new InteractiveEvent(icanvas, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].mouseup(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }

  mousemove(icanvas, e) {
    let event = new InteractiveEvent(icanvas, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].mousemove(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  keypress(icanvas, e) {
    let event = new InteractiveEvent(icanvas, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].keypress(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchTap(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchTap(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchPan(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchPan(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchSwipe(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchSwipe(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchDistance(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchDistance(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchRotate(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchRotate(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  onTouchGesture(e) {
    let event = new InteractiveEvent(undefined, e);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].onTouchGesture(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  file_dropped(e, files) {
    let event = new DragDropEvent(this.scene.icanvas, e, files);
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].file_dropped(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }
  drag_file(e, files) {
    let event = new DragDropEvent(this.scene.icanvas, e, files);
    event.isDrag = true;
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].drag_file(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }

  activate_event(event) {
    for (var each = this.scenes.length - 1; each >= 0; each--) {
      this.scenes[each].activate_event(event);
      if (event.isStopPropagation) {
        break;
      }
    }
  }

  activate(value, value_context) {
    let event = new InteractiveEvent();
    event.activate_value = value;
    event.activate_value_context = value_context;
    this.activate_event(event);
  }
}
