import { ImageCanvasElement } from "../display/imageCanvasElement";
import { FileResource } from "./FileResource";

let c2 = require("c2.js");

export const SizeType = {
  ScaleToFitScreen: 0,
  ScaleToFitRectangle: 1,
  Normal: 2,
};

export class ImageResource extends FileResource {
  static errrorImage;
  image_bitmap;
  image_request;
  image_promise;
  isError;
  canvas_element;
  getRectangle;
  sizeType = SizeType.ScaleToFitScreen;
  centerRotateDegrees;

  onError() {
    this.isError = true;
  }
  constructor(image_request, image_dictionary) {
    super();
    this.image_request = image_request;

    this.image_bitmap = image_dictionary[this.image_request.filename];
    var self = this;

    if (this.image_bitmap) {
      if (!this.image_bitmap.complete) {
        this.image_promise = new Promise((resolve, reject) => {
          this.image_bitmap.addEventListener("load", () => {
            self.image_promise = undefined;
            resolve(self.image_bitmap);
          });
          self.image_bitmap.addEventListener("error", function () {
            self.image_promise = undefined;
            self.onError();
            image_dictionary[self.image_request.filename]=undefined;
            resolve(self.image_bitmap);
          });
        });
      }

      return;
    }

    this.image_bitmap = new Image();
    image_dictionary[this.image_request.filename] = this.image_bitmap;

    this.image_promise = new Promise((resolve, reject) => {
      this.image_bitmap.addEventListener("load", () => {
        self.image_promise = undefined;
        resolve(this.image_bitmap);
      });
      self.image_bitmap.addEventListener("error", function () {
        self.image_promise = undefined;
        self.onError();
        console.warn("missing resource: "+self.image_request.filename);
        image_dictionary[self.image_request.filename]=undefined;
        resolve(self.image_bitmap);
      });

      image_request.server_file_cache.loadFromCacheOrUrl(this.image_bitmap, this.image_request.toUrlPath());
    });
  }

  isLoading() {
    return this.image_promise != undefined;
  }
  //if(ImageResource.errrorImage==undefined){
  // ImageResource.errrorImage=new Image(1920, 1080);
  // }
  getLoadingPromise() {
    return this.image_promise;
  }
  imageWidth() {
    if (this.isError) {
      return 1920;
    }
    return this.image_bitmap.width;
  }
  imageHeight() {
    if (this.isError) {
      return 1080;
    }
    return this.image_bitmap.height;
  }
  toRect(icanvas) {
    // if (!image || image.width == 0 || image.height == 0) {
    //   console.warn(`scene ${this.name} undefined image`);
    //   return new c2.Rect(0, 0, 0, 0);
    // }
    if (this.sizeType == SizeType.ScaleToFitScreen) {
      var hRatio = icanvas.get_width() / this.imageWidth();
      var vRatio = icanvas.get_height() / this.imageHeight();
      var ratio = Math.min(hRatio, vRatio);
      var result = new c2.Rect(0, 0, this.imageWidth() * ratio, this.imageHeight() * ratio);
      return result;
    }
    if (this.sizeType == SizeType.ScaleToFitRectangle) {
      let rectangle = this.getRectangle();
      var hRatio = rectangle.w / this.imageWidth();
      var vRatio = rectangle.h / this.imageHeight();
      var ratio = Math.min(hRatio, vRatio);
      var result = new c2.Rect(
        rectangle.p.x,
        rectangle.p.y,
        this.imageWidth() * ratio,
        this.imageHeight() * ratio
      );
      return result;
    }
    if (this.sizeType == SizeType.Normal) {
      let rectangle = this.getRectangle();
      var result = new c2.Rect(rectangle.p.x, rectangle.p.y, this.imageWidth(), this.imageHeight());
      return result;
    }
  }

  getCanvasElement() {
    if (this.canvas_element == undefined) {
      this.canvas_element = new ImageCanvasElement(this, this.image_bitmap);
    }
    return this.canvas_element;
  }
}
