import { DrawScope } from "./DrawScope.js";
import { Player } from "./Player.js";
import { SceneGraphSet } from "./sceneGraph/sceneGraphSet.js";
import { SceneGraph } from "./sceneGraph/scene_graph.js";
import { Treeview } from "./view/treeview.js";
import { EmptySceneGraphNode, SceneGraphNode } from "./sceneGraph/scene_graph_node";

export class Simulation {
  icanvas;
  resources;
  sceneGraphSet;
  json;
  account;
  server;
  audio;
  default_scene_graph;
  sceneGraphNode;
  player;

  // get player() {
  //   return this.sceneGraphSet.player;
  // }
  get playerSceneGraphNode() {
    return this.player.sceneGraphNode;
  }
  // get sceneGraph() {
  //   return this.sceneGraphSet.sceneGraphNone;
  // }
  get application() {
    return this.account.application;
  }

  get drawScope() {
    let result = this.account.json_simulation.drawScope;

    if (result == undefined) {
      result = DrawScope.Normal;
    }
    return result;
  }

  set drawScope(v) {
    this.account.json_simulation.drawScope = v;
    this.updateCanvasDrawScope();
  }

  constructor(resources, icanvas, account, server, audio) {
    this.resources = resources;
    this.icanvas = icanvas;
    this.account = account;
    this.server = server;
    this.sceneGraphSet = new SceneGraphSet(this);

    this.audio = audio;
  }

  async initialize() {
    this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());

    this.default_scene_graph = new SceneGraph(this, await this.resources.getOrFetchSceneGraphJson(""));
    await this.resources.LoadSceneGraphs(this.default_scene_graph);
    
    this.updateCanvasDrawScope();
    
    this.sceneGraphNode = new SceneGraphNode(this);

    if (this.account) {
      this.player = new Player(this.account, this);
      //this.player.initializeNavigation();
    }

   // this.sceneGraphSet.initialize(this.account);

    this.initializeSceneGraphNode();
  }

  initializeSceneGraphNode() {
    this.sceneGraphNode.initiaizeBegin();

    this.sceneGraphSet.initializeGraphNodeScene(this.sceneGraphNode);

    this.player?.initializeSceneGraphNode();   

    this.sceneGraphNode.initiaizeEnd();
  }

  updateCanvasDrawScope() {
    this.icanvas.drawScope = this.drawScope;
  }

  start() {
    // this.sceneGraphSet.start();
  }

  // update(icanvas) {
  //   this.sceneGraph.update(icanvas);
  // }
  drawFrame(icanvas) {
    this.playerSceneGraphNode.drawFrame(icanvas);
  }

  mousedown(icanvas, e) {
    this.playerSceneGraphNode.mousedown(icanvas, e);
  }
  mouseup(icanvas, e) {
    this.playerSceneGraphNode.mouseup(icanvas, e);
  }
  mousemove(icanvas, e) {
    this.playerSceneGraphNode.mousemove(icanvas, e);
  }
  keypress(icanvas, e) {
    if (e.key == "1") {
      this.toggleDrawScope();
      this.icanvas.invaidate();
    }

    this.playerSceneGraphNode.keypress(icanvas, e);
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(this.application.name, this.account.name, "simulation.storage");
  }

  saveState() {
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  shutdown() {
    this.saveState();
  }

  toggleDrawScope() {
    if (this.drawScope == DrawScope.Normal) {
      this.drawScope = DrawScope.Debug;
    } else {
      this.drawScope = DrawScope.Normal;
    }
  }

  onTouchTap(e) {
    this.playerSceneGraphNode.onTouchTap(e);
  }
  onTouchPan(e) {
    this.playerSceneGraphNode.onTouchPan(e);
  }
  onTouchSwipe(e) {
    this.playerSceneGraphNode.onTouchSwipe(e);
  }
  onTouchDistance(e) {
    this.playerSceneGraphNode.onTouchDistance(e);
  }
  onTouchRotate(e) {
    this.playerSceneGraphNode.onTouchRotate(e);
  }
  onTouchGesture(e) {
    this.playerSceneGraphNode.onTouchGesture(e);
  }

  collectEditableProperties(layout) {}
  getAuthorInterfaceName() {
    return "Simulation";
  }
  createAuthorInterfaceElement() {
    // let layout = new Treeview();
    // layout.buildElementFromJson(this.sceneGraph.json, 1);
    // return layout.element;
  }
  file_dropped(e, files) {
    this.sceneGraph.file_dropped(e, files);
  }
  drag_file(e, files) {
    this.sceneGraph.drag_file(e, files);
  }

  activate(event) {
    this.sceneGraphNode.activate_event(event);
  }
}
