export class FileResourceRequest {
  static LargeDataFolder = "dataLarge/";
  //filename;
  system_path;
  path;
  category_path;
  categorized_path;
  name;
  extension;
  fullpath;
  isWorkInProgress;
  isArchive;
  //isDataLarge;

  constructor(
    path = "",
    name = "",
    extension = "",
    category_path = "",
    categorized_path = "",
    isDataLarge = true,
    isWorkInProgress = false,
    isArchive = false
  ) {
    this.setPath(path, false);
    this.setCategoryPath(category_path, false);
    this.setCategorizedPath(categorized_path, false);

    this.system_path = "/assets/";

    if (isDataLarge) {
      this.system_path += FileResourceRequest.LargeDataFolder;
    }

    this.setExtension(extension,false);
    this.name = name;
    this.isWorkInProgress = isWorkInProgress;
    this.isArchive = isArchive;
    this.updateFullpath();
    //if (path.startsWith(FileResourceRequest.LargeDataFolder)) {
    // this.isDataLarge = true;
    //  this.path = this.path.slice(FileResourceRequest.LargeDataFolder.length);
    //}
  }

  setCategoryPath(category_path, isUpdateFullpath = true) {
    this.category_path = category_path;
    if (this.category_path && this.category_path.endsWith("/") == false) {
      this.category_path += "/";
    }
    if (isUpdateFullpath) {
      this.updateFullpath();
    }
  }

  setCategorizedPath(categorized_path, isUpdateFullpath = true) {
    this.categorized_path = categorized_path;
    if (this.categorized_path && this.categorized_path.endsWith("/") == false) {
      this.categorized_path += "/";
    }
    if (isUpdateFullpath) {
      this.updateFullpath();
    }
  }

  setPath(path, isUpdateFullpath = true) {
    this.path = path;
    if (this.path && this.path.endsWith("/") == false) {
      this.path += "/";
    }
    if (isUpdateFullpath) {
      this.updateFullpath();
    }
  }

  setExtension(extension, isUpdateFullpath = true) {
    this.extension = extension;
    if (isUpdateFullpath) {
      this.updateFullpath();
    }
  }
  get filename() {
    return this.fullpath;
  }

  // prependPath(path, isUpdateFullpath = true) {
  //   this.path = `${path}${this.path}`;
  //   if (isUpdateFullpath) {
  //     this.updateFullpath();
  //   }
  // }

  // appendPath(path, isUpdateFullpath = true) {
  //   this.path = `${this.path}${path}`;
  //   if (isUpdateFullpath) {
  //     this.updateFullpath();
  //   }
  // }

  updateFullpath() {
    var updatedPath = this.system_path + this.path + this.category_path + this.categorized_path;

    if (this.isWorkInProgress) {
      updatedPath = `${updatedPath}${"wip-demo/"}`;
    }

    this.fullpath = `${updatedPath}${this.name}${this.extension}`;
  }
  toUrlPath() {
    return this.fullpath;
    // if (this.isDataLarge) {
    //return `/assets/${this.filename}`;
    // } else {
    //  return `/assets/images/${this.filename}`;
    //}
  }
}
