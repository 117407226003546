export class CanvasElement {
  is_invalidating_draw;
  resource;
  draw_order;
  //waitingOn = [];
  //resources = [];
  isHidden;
  opacity;

  constructor(resource = undefined, is_invalidating_draw = true) {
    this.resource = resource;
    this.is_invalidating_draw = is_invalidating_draw;
    this.draw_order=1;
  }
  onCanvasResized(icanvas) {}
  draw(icanvas) {}
  initialize(icanvas) {}
  uninitialize(icanvas) {}
  invalidate() {
    this.is_invalidating_draw = true;
  }
  validate(icanvas) {
    this.is_invalidating_draw = false;
  }
  // waitOn(item) {
  //   if (item == undefined) {
  //     return;
  //   }

  //   this.waitingOn.push(item);
  // }
  // isDependencyLoading() {
  //   for (let eachItem in this.waitingOn) {
  //     let each = this.waitingOn[eachItem];
  //     if (each.isLoading()) {
  //       return true;
  //     }
  //   }

  //   return false;
  // }

  isLoading() {
    return this.resource?.isLoading();
  }

  // getFirstDependencyLoadingPromise() {
  //   for (let eachItem in this.waitingOn) {
  //     let each = this.waitingOn[eachItem];
  //     if (each.isLoading()) {
  //       return each.getFirstLoadingPromise();
  //     }
  //   }

  //   return undefined;
  // }

  getFirstLoadingPromise() {
    return this.resource?.getLoadingPromise();
  }
}
