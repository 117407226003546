
export class InteractiveEvent {
    icanvas;
    e;
    isStopPropagation;

    activate_value;
    activate_value_context;

    constructor(icanvas = undefined, e = undefined) {
        this.icanvas = icanvas;
        this.e = e;
    }
}
