import { Stem } from "../../audio/stem";
import { WebAudio } from '../../audio/webAudio';
import { Action } from "../actions/action";

export class PlayAudioAction extends Action {
  value;
  value_context;

  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
    this.value_context = json["value_context"];
  }

  newSoundEffectStem(interactive, json) {
    var result = new Stem(json, interactive.scene.getResourcePathFunction());
    result.json.isLoop = false; 
    result.json.layer = WebAudio.effectslayerName;
    return result;
  }

  run(interactive) {
    var stems = [];

    if (Array.isArray(this.value)) {
      this.value.forEach((element) => {
        stems.push(this.newSoundEffectStem(interactive, element));
      });
    } else {
      stems.push(this.newSoundEffectStem(interactive, this.value));
    }

    interactive.scene.simulation.audio.playSoundEffects(stems);
  }
}
