export class InteractiveInput {
  static typename = "interactive.input";

  static keybindings = {
    rotate: {
      right: ["ArrowRight", "d"],
      left: ["ArrowLeft", "a"],
    },
    move: {
      forward: ["ArrowUp", "w"],
      back: ["ArrowDown", "s"],
    },
  };

  interactive;
  verb;
  verb_value;
  keys = [];

  constructor(interactive, json) {
    this.interactive = interactive;

    for (let each in json) {
      this.verb = each;
      this.verb_value = json[each];
      this.keys = InteractiveInput.keybindings[this.verb][this.verb_value];

      break;
    }
  }

  drawFrame(icanvas) {}

  keypress(ievent) {
    for (let each in this.keys) {
      if (this.keys[each] == ievent.e.key) {
        this.interactive.onInteractiveInput(ievent, this);
        break;
      }
    }
  }

  activate(ievent) {
    
      for (const property in ievent.activate_value_context) {
        if (this.verb === property) {
          if (this.verb_value === ievent.activate_value_context[property]) {
            this.interactive.onInteractiveInput(ievent, this);
            break;
          }
        }
      }
   
  }

  onTouchTap(ievent) {}
  onTouchPan(ievent) {}
  onTouchSwipe(ievent) {
    let event = ievent.e.events[0];
    let data = ievent.e.data[0];
    let dir = data.currentDirection;

    if (dir > 180 - 20 && dir < 180 + 20) {
      ievent.e.key = "d";
      this.keypress(ievent);
    } else if (dir > 360 - 20 || dir < 20) {
      ievent.e.key = "a";
      this.keypress(ievent);
    } else if (dir > 270 - 20 && dir < 270 + 20) {
      ievent.e.key = "w";
      this.keypress(ievent);
    } else if (dir > 90 - 20 && dir < 90 + 20) {
      ievent.e.key = "s";
      this.keypress(ievent);
    }
  }
  onTouchDistance(ievent) {}
  onTouchRotate(ievent) {}
  onTouchGesture(ievent) {}
}
