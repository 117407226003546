import { Interactive } from "./Interactive.js";
let c2 = require("c2.js");

export class Interactives {
  scene;
  interactives = [];

  constructor(scene) {
    this.scene = scene;
    //this.initializeFromSceneJson();
  }

  addInteractive(name, json, isTemporary = false) {
    var i = this.newInteractive(json, isTemporary);
    i.name = name;
    this.interactives.push(i);
    return i;
  }
  removeInteractive(name, isTemporary = false) {
    var temp = this.interactives.filter((each) => !(each.name == name));
    this.interactives = temp;
  }
  removeTemporary() {
    var temp = this.interactives.filter((each) => !(each.isTemporary == true));
    this.interactives = temp;
  }

  findInteractiveByName(name, isTemporary = false) {
    for (let i in this.interactives) {
      var each = this.interactives[i];
      if (each.name == name && each.isTemporary == isTemporary) {
        return each;
      }
    }
  }

  newInteractive(json, isTemporary = false) {
    var i = new Interactive(json);
    i.isTemporary = isTemporary;
    i.scene = this.scene;
    return i;
  }
  initializeFromSceneJson() {
    var json = this.scene.json.interactive;
    if (json) {
      for (let each in json) {
        var i = this.newInteractive(json[each]);

        this.interactives.push(i);
      }
    }
  }
  // update(icanvas) {
  //   this.interactives.forEach((v) => v.update(icanvas));
  // }
  collectCanvasElements(result) {
    this.interactives.forEach((element) => {
      element.collectCanvasElements(result);
    });
  }

  add(interactive) {
    this.interactives.push(interactive);
  }

  start() {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.start();
    }
  }

  stop() {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.stop();
    }
  }

  drawFrame(icanvas) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.drawFrame(icanvas);
    }
  }
  isIEventOnInteractiveArea(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      if (i.isIEventOnInteractiveArea(ievent)) {
        return true;
      }
    }
    return false;
  }
  mousedown(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.mousedown(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  mouseup(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];

      i.mouseup(ievent);

      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  mousemove(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.mousemove(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  keypress(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.keypress(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchTap(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.onTouchTap(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchPan(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.onTouchPan(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchSwipe(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.onTouchSwipe(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchDistance(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.onTouchDistance(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchRotate(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.onTouchRotate(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchGesture(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.onTouchGesture(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  activate(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.activate(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  file_dropped(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.file_dropped(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
    this.try_add_file_as_scene(ievent);

    ievent.isStopPropagation = true;
  }
  drag_file(ievent) {
    for (let each in this.interactives) {
      let i = this.interactives[each];
      i.drag_file(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }

    this.preview_add_file_as_scene(ievent);
    ievent.isStopPropagation = true;
  }

  static preview_name = "preview";
  preview_add_file_as_scene(ievent) {
    // var file=ievent.files[0].file;

    // var name = Interactive.getNameFromFilename(file);

    // if (this.scene.findInteractiveByName(name)) {
    //   console.warn("duplicate interactive name");
    //   return;
    // }

    var e = ievent.e;

    var found = this.scene.findInteractiveByName(Interactives.preview_name, true);

    if (!found) {
      var json = {
        name: Interactives.preview_name,
        "interactive.area": {
          "geometry.rectangle": [0.0, 0.0, 0.05, 0.05],
        },
      };
      found = this.scene.addInteractive(Interactives.preview_name, json, true);
    }
    var relative = this.scene.convertEventWithPointToRelativePoint(e);
    found.setAreaReletivePosition(relative);
  }
  try_add_file_as_scene(ievent) {
    var found = this.scene.removeInteractive(Interactives.preview_name);
    ievent.icanvas.invalidate();
  }
}
