//https://github.com/ren-yuan/c2.js
let c2 = require("c2.js");

export function mouseEventToPoint(e) {
  return new c2.Point(e.x, e.y);
}

export class Geometry {
  shape;
  isRelativeTo;
  draw(icanvas, drawScope) {}
  to_point(isRelativeTo) {}
}

//https://c2js.org/docs/classes/geometry.rect.html#constructor
export class RectangleGeometry extends Geometry {
  rect_pos_size;

  constructor(geometry,isRelativeTo=undefined) {
    super();
    this.isRelativeTo=isRelativeTo;
    this.rect_pos_size = geometry;
    super.shape = new c2.Rect(
      this.rect_pos_size[0],
      this.rect_pos_size[1],
      this.rect_pos_size[2],
      this.rect_pos_size[3]
    );
  }
  to_point() {
    let absolute_shape = this.get_absolute_shape();

    return absolute_shape.p;
  }
  draw(icanvas, drawScope) {
    let absolute_shape = this.get_absolute_shape();
    icanvas.draw_point(absolute_shape.p, 3, drawScope);
    icanvas.draw_rect(absolute_shape, drawScope);
  }

  get_absolute_shape() {
    if (this.isRelativeTo) {
      let relativeTo = this.isRelativeTo.toRect();
      let result = new c2.Rect(
        relativeTo.w * this.shape.p.x,
        relativeTo.h * this.shape.p.y,
        relativeTo.w * this.shape.w,
        relativeTo.h * this.shape.h
      );

      return result;
    }
    return this.shape;
  }

  containsPoint( point) {
 
    let absolute_shape = this.get_absolute_shape();
    return absolute_shape.intersects(point);
  }
}

