import { Player } from "../Player";
import { ScenePath } from "./ScenePath";
import { SceneGraph } from "./scene_graph";
import { EmptySceneGraphNode, SceneGraphNode } from "./scene_graph_node";

export class SceneGraphSet {
  get resources() {
    return this.simulation.resources;
  }
  get sceneGraphNode() {
    return this.player.sceneGraphNode;
  }
  simulation;
  get player(){
    return this.simulation.player;
  }
  scene_graphs = [];

  get globalSceneGraph() {
    return this.simulation.default_scene_graph;
  }
  get defaultNavigationSceneGraphName() {
    return this.globalSceneGraph.defaultSceneGraph;
  }
  get defaultNavigationSceneGraph() {
    return this.getSceneGraph(this.defaultNavigationSceneGraphName);
  }
  constructor(simulation) {
    this.simulation = simulation;
  }

  // initialize(/*player_account = undefined*/) {
    
  // }
  tryPopulateDefaultPaths(path){
    if(path.sceneName==undefined){
      var graph=this.getSceneGraphByPath(path);
      if(graph){
        path.sceneName=graph.json.defaultScene;
      }
    }
  }
  getSceneGraphByIndex(index) {
    return this.scene_graphs[index];
  }
  getSceneGraphByPath(path) {
    return this.getSceneGraph(path.sceneGraphName);
  }
  getSceneGraphSceneByPath(path) {
    var graph = this.getSceneGraphByPath(path);


    return graph?.findSceneByPath(path);
  }
  // newSceneGraphNode(path) {
  //   var node = new SceneGraphNode();

  //   var scene = this.getSceneGraphSceneByPath(path);

  //   if (scene) {
  //     node.scenes.push(scene);
  //   }

  //   return node;
  // }
  initializeGraphNodeScene(node) {
    var common = this.globalSceneGraph.json.sceneGraphSetCommon;
    if (!common) {
      return;
    }
    var layers = common.sceneGraphLayers;
    if (!common) {
      return;
    }

    layers.forEach((element) => {
      if (element.sceneGraphName) {
        var found = this.getSceneGraph(element.sceneGraphName);
        if (found) {
          node.addAndStart(found.defaultOrFirstScene);
        }
      }
    });
  }

  // replaceOrAddGraphNodeScene(node, old_path, new_path) {
  //   node.replaceOrAddGraphNodeScene(this, old_path, new_path);
  // }

  getSceneGraph(name) {
    for (let index = 0; index < this.scene_graphs.length; index++) {
      const element = this.scene_graphs[index];
      if (element.name === name) {
        return element;
      }
    }

    var path = this.resources.getNavigationGraphPathFromName(name);
    let json = this.resources.scene_graph_json[path];
    if (json) {
      var found = new SceneGraph(this.simulation, json, this.globalSceneGraph, path, this.scene_graphs.length);

      this.scene_graphs.push(found);
      return found;
    }
  }
}

// start() {}

// get navigationHistory() {
//   return this.player.navigationHistory;
// }

// tryPushFirstNavigationHistory() {
//   this.player.tryPushFirstNavigationHistory();
// }
// pushNavigationHistory(scene) {
//   this.player.pushNavigationHistory(scene);
// }
// canNavigateBack() {
//   return this.player.canNavigateBack();
// }
// navigateBack() {
//   this.player.navigateBack();
// }
// navigateHome() {
//   this.player.navigateHome();
// }
