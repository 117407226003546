import { Action } from "../actions/action";


export class BackAction extends Action {

  constructor(verb, name, json) {
    super(verb, name);
  }
  canRun(interactive) {
    return interactive.scene.canNavigateBack();
  }
  run(interactive) {
    console.log("action:Back");
    interactive.scene.navigateBack();
  }
}
