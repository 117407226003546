/* context

*/
import * as mathHelper from "../mathHelper.js";
import { CanvasElement } from "./CanvasElement.js";

export class ImageCanvasElement extends CanvasElement {
  element;

  constructor(resource, element, is_invalidating_draw = true) {
    super(resource, is_invalidating_draw);
    this.element = element;
  }

  draw(icanvas) {
    if (this.resource.isLoading()) {
      return;
    }

    let image_rect = this.resource.toRect(icanvas);

    let drawOffsetX = 0;
    let drawOffsetY = 0;

    if (this.resource.centerRotateDegrees) {
      // https://stackoverflow.com/questions/3793397/html5-canvas-drawimage-with-at-an-angle
      let r = mathHelper.degreesToRadians(this.resource.centerRotateDegrees);

      icanvas.ctx.save();
      icanvas.ctx.translate(image_rect.p.x + image_rect.w / 2, image_rect.p.y + image_rect.h / 2);
      icanvas.ctx.rotate(r);
      // icanvas.ctx.translate(image_rect.w/2, image_rect.h/2);
      drawOffsetX = -image_rect.w / 2;
      drawOffsetY = -image_rect.h / 2;
      // icanvas.ctx.translate(this.element.width/2, this.element.height/2);
      //  icanvas.ctx.translate((image_rect.w/2), (image_rect.h/2));
    } else {
      drawOffsetX = image_rect.p.x;
      drawOffsetY = image_rect.p.y;
    }

    this.width = image_rect.w;
    this.height = image_rect.h;

    if (this.resource.isError) {
      icanvas.ctx.rect(drawOffsetX, drawOffsetY, image_rect.w, image_rect.h);
    } else {
      console.log("visual:image:draw:"+this.element.currentSrc);
      icanvas.ctx.drawImage(this.element, drawOffsetX, drawOffsetY, image_rect.w, image_rect.h);
    }

    if (this.resource.centerRotateDegrees) {
      icanvas.ctx.restore();
    }
  }
}
