import { ImageResource } from "./resources/ImageResource.js";
import { FileResourceRequest } from "./resources/FileResourceRequest.js";
import { VideoResource } from "./resources/VideoResource.js";
import { SceneGraph } from "./sceneGraph/scene_graph.js";
import { AudioResource } from "./resources/audioResource.js";

export class Resources {
  images = {};
  videos = {};
  //audios = {};
  server;
  scene_graph_json = {};

  async Initialize() {}

  async LoadSceneGraphs(g) {
    let fetchSceneGraphs = [];
    g.json.navigationGraphs?.forEach((element) => {
      fetchSceneGraphs.push(this.getOrFetchSceneGraphJson(element));
    });
    await Promise.all(fetchSceneGraphs);
  }

  async getOrFetchSceneGraphJson(name) {
    var path = this.getNavigationGraphPathFromName(name);

    var found = this.scene_graph_json[path];

    if (!found) {
      found = await this.getJsonAsync(path);

      if (found == 404) {
        console.log(`json:resources:404 error ${path}`);
      }
      this.scene_graph_json[path] = found;
    }
    return found;
  }

  getNavigationGraphPathFromName(name) {
    var filename = name;
    if (filename) {
      filename += ".";
    }
    filename += "navigation.graph";
    var path = this.getJsonPath(filename);
    return path;
  }

  getJsonPath(name) {
    return `/assets/data/${name}.json`;
  }

  async getJsonAsync(path) {
    console.log(`json:resources:open ${path}`);
    const r = await fetch(path);
    const j = await r.json();

    return j;
  }

  combineJsonResourceNames(args, append_extension = true) {
    let result = "";
    for (let each in args) {
      let each_value = args[each];
      if (each_value) {
        result += each_value;
        result += ".";
      }
    }
    if (append_extension) {
      result += "json";
    } else {
      result = result.slice(0, -1);
    }

    return result;
  }

  combineJsonResourceName() {
    let result = "";
    for (let each in arguments) {
      let each_value = arguments[each];
      if (each_value) {
        result += each_value;
        result += ".";
      }
    }

    result += "json";

    return result;
  }

  requestImageResource(request) {
    return this.requestPngImageResource(request);
  }
  disposeImageResource(resource) {}

  requestWebPImageResource(request) {}
  disposeWebPImageResource(resource) {}

  requestPngImageResource(request) {
    request.setExtension(".png");
    request.setCategoryPath("visuals/images/png/");
    request.server_file_cache = this.server.server_file_cache;
    return new ImageResource(request, this.images);
  }
  disposePngImageResource(resource) {}

  requestMp4VideoResource(request) {
    request.setExtension(".mp4");
    request.setCategoryPath("visuals/video/");

    let found = this.videos[request.fullpath];

    if (found) {
      return found;
    }

    request.server_file_cache = this.server.server_file_cache;
    found = new VideoResource(request);
    this.videos[request.fullpath] = found;
    return found;
  }
  disposeMp4VideoResource(resource) {}

  requestMp3AudioResource(request) {
    request.setExtension(".mp3");
    request.setCategoryPath("audio/");

    //let found = this.audios[request.fullpath];

    //if (found) {
    //  return found;
    // }

    request.server_file_cache = this.server.server_file_cache;
    var found = new AudioResource(request);
    //this.audios[request.fullpath] = found;
    return found;
  }

  disposeMp3AudioResource(resource) {
    resource?.dispose();
  }

  disposeResource(resource) {
    if (resource.type == "image") {
      this.disposeImageResource(resource);
    }
    if (resource.type == "video") {
      this.disposeMp4VideoResource(resource);
    }
    if (resource.type == "audio") {
      this.disposeMp3AudioResource(resource);
    }
  }
  requestResource(name, type, path, path2) {
    let request = new FileResourceRequest(path, name, "", "", path2);
    var r;
    if (type == "image") {
      r = this.requestImageResource(request);
    }
    if (type == "video") {
      r = this.requestMp4VideoResource(request);
    }
    if (type == "audio") {
      r = this.requestMp3AudioResource(request);
    }

    r.type = type;
    return r;
  }

  requestResourceFromJson(json, path, path2) {
    if (json.image) {
      let result = this.requestResource(json.image, "image", path, path2);
      result.centerRotateDegrees = json["image.rotate"];
      return result;
    } else if (json.video) {
      return this.requestResource(json.video, "video", path, path2);
    }
  }

  getLocalStorageItem(name, ifNotFound = "") {
    let found = window.localStorage.getItem(name);
    if (!found) {
      found = ifNotFound;
    }
    return found;
  }

  getLocalStorageJsonItem(name, ifNotFound = "{}", createIfNotFound = true) {
    let item = this.getLocalStorageItem(name, ifNotFound);
    if (item) {
      let json = JSON.parse(item);

      if (createIfNotFound) {
        this.setLocalStorageItemAsJson(name, json, true);
      }

      return json;
    }
    return undefined;
  }
  setLocalStorageItem(name, value, createIfNotFound = true) {
    
    if(!createIfNotFound && window.localStorage.getItem(name)===null){
      return;
    }
    window.localStorage.setItem(name, value);
  }
  setLocalStorageItemAsJson(name, object_value, createIfNotFound = false) {
    let value = JSON.stringify(object_value);
    this.setLocalStorageItem(name, value, createIfNotFound);
  }
}
