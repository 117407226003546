import { InteractiveCanvas } from "../interactive_canvas.js";
import * as htmlHelper from "../htmlHelper.js";
import { Treeview } from "../view/treeview.js";
import { ReadonlyProperty, BooleanEditableProperty, NumberEditableProperty } from "./../view/propertyEditor";

export class AuthorInterface {
  icanvas;
  resources;
  json;
  isActive;
  account;

  nav;
  sideNav;
  //sideNav_accordianGroup;
  sideNav_treeView;
  components = [];

  constructor(resources, icanvas, account) {
    this.resources = resources;
    this.icanvas = icanvas;
    this.account = account;
  }

  get application() {
    return this.account.application;
  }

  get isVisible() {
    let result = this.json.isVisible;

    if (result == undefined) {
      result = false;
    }
    return result;
  }

  set isVisible(v) {
    this.json.isVisible = v;
    this.updateActive();
  }

  initialize() {
    this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
  }

  start() {
    this.updateActive();
  }

  updateActive() {
    if (this.isVisible && !this.isActive) {
      this.addToDOM();
    } else if (!this.isVisible && this.isActive) {
      this.removeFromDOM();
    }
  }
  deactivate() {
    this.json.isDeactivate = true;
    this.json.isDeactivateVisible = this.isVisible;
    if (this.isVisible) {
      this.isVisible = false;
    }
  }

  reactivate() {
    if (this.json.isDeactivate) {
      this.json.isDeactivate = false;
      if (this.json.isDeactivateVisible) {
        this.isVisible = true;
      }
    }
  }

  createSidebar() {
    let result = document.createElement("div");
    result.style.padding = "4px";
    result.classList.add("sidenav");
    this.sideNav_treeView = new Treeview(true);

    this.components.forEach((element) => {
      if (element.addAuthorInterfaceElementToTreeview) {
        element.addAuthorInterfaceElementToTreeview(this.sideNav_treeView);
      } else {
        if (element.createAuthorInterfaceElement) {
          let elm = element.createAuthorInterfaceElement();
          this.sideNav_treeView.addItem(element.getAuthorInterfaceName(), elm, false);
        }
      }
    });

    result.appendChild(this.sideNav_treeView.element);
    return result;
  }

  createNavbar() {
    let result = document.createElement("nav");
    result.classList.add("navbar");
    result.classList.add("bg-body-tertiary");

    let form = document.createElement("form");
    form.classList.add("container-fluid");
    form.classList.add("justify-content-start");

    result.appendChild(form);

    return result;
  }

  addToDOM() {
    let viewport = document.getElementById(InteractiveCanvas.viewportId);

    this.nav = this.createNavbar();
    this.sideNav = this.createSidebar();

    viewport.parentElement.insertBefore(this.nav, viewport);

    viewport.style.marginLeft = "448px";
    viewport.parentElement.insertBefore(this.sideNav, viewport);

    //this.sideNav_accordianGroup.addCallbacks();

    this.isActive = true;
  }

  removeFromDOM() {
    let viewport = document.getElementById(InteractiveCanvas.viewportId);

    this.nav.remove();
    this.nav = undefined;

    this.sideNav.remove();
    this.sideNav = undefined;
    viewport.style.marginLeft = "0px";

    this.isActive = false;
  }

  drawFrame(icanvas) {}
  mousedown(icanvas, e) {}
  mouseup(icanvas, e) {}
  mousemove(icanvas, e) {}
  keypress(icanvas, e) {
    if (e.key == "2") {
      this.isVisible = !this.isVisible;
      this.updateActive();
      icanvas.updateCanvasSize();
      icanvas.invaidate();
    }
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(this.application.name, this.account.name, "author.storage");
  }
  saveState() {
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }

  shutdown() {
    this.saveState();
  }

  onTouchTap(e) {}
  onTouchPan(e) {}
  onTouchSwipe(e) {}
  onTouchDistance(e) {}
  onTouchRotate(e) {}
  onTouchGesture(e) {}

  static collectEditablePropertiesFromProperties(client_values, ui, default_values, layout) {
    for (const property in ui) {
      var editor;
      var value;

      var get_value = () => {
        if (client_values.hasOwnProperty(property)) {
          return client_values[property];
        } else if (default_values.hasOwnProperty(property)) {
          return default_values[property];
        }
      };
      value = get_value();

      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
      if (typeof value == "boolean") {
        editor = new BooleanEditableProperty();
      } else if (typeof value == "number") {
        editor = new NumberEditableProperty();
      } else {
        editor = new ReadonlyProperty();
      }

      if (ui.hasOwnProperty(property)) {
        var ui_properties = ui[property];
        if (ui_properties.hasOwnProperty("isEnabled")) {
          var isEnabled = ui_properties["isEnabled"];
          editor.setEnabled(isEnabled);
        }
      }

      let property_interface = Object.assign(editor, {
        name: property,
        getValue: () => {
          return get_value();
        },
        setValue: (v) => {
          return (client_values[property] = v);
        },
      });
      layout.addAsTableRow(property_interface.getEditorElements());
    }
  }
}
