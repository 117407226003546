export class Action {
  static typename = "action";
  verb;
  name;

  constructor(verb, name) {
    this.verb = verb;
    this.name = name;
  }
   canRun(interactive) {
    return true;
   }
   run(interactive) {}
}


