/* context

https://rotato.app/blog/transparent-videos-for-the-web
https://www.tutorialspoint.com/How-do-I-make-a-transparent-canvas-in-HTML5
https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Manipulating_video_using_canvas

*/

import { CanvasElement } from "./CanvasElement.js";

export class VideoCanvasElement extends CanvasElement {
  element;
  canvas;
  is_added_to_viewport;

  constructor(resource, element, is_invalidating_draw = true) {
    super(resource, is_invalidating_draw);

    this.element = element;
  }

  initialize(icanvas) {
    super.initialize(icanvas);
  }

  onCanvasResized(icanvas) {
    super.onCanvasResized(icanvas);
    if (this.is_added_to_viewport) {
      this.updateElementSize(icanvas);
    }
  }

  validate(icanvas) {
    super.validate(icanvas);

    if (!this.is_added_to_viewport) {
      this.updateElementSize(icanvas);
      icanvas.viewport.appendChild(this.element);

      if (this.element.paused) {
        var playing = this.element.play();
        playing.catch((e) => {
          console.log("error:video:" + this.element.currentSrc +"\n"+ e);
        });
      }

      this.is_added_to_viewport = true;
    }
  }

  updateElementSize(icanvas) {
    let image_rect = this.resource.toRect(icanvas);
    this.element.width = image_rect.w;
    this.element.height = image_rect.h;
  }

  uninitialize(icanvas) {
    super.uninitialize(icanvas);
    if (this.is_added_to_viewport) {
      this.element.pause();

      icanvas.viewport.removeChild(this.element);
      this.is_added_to_viewport = false;
    }
  }
}
